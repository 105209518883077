import React from "react"
import PortfolioLayout from "../components/portfolioLayout"
import { Helmet } from "react-helmet"

export default function Portfolio() {

    return <PortfolioLayout>
              <Helmet>
                <title>Portfolio</title>
                <meta name="description" content="" />
              </Helmet>
            </PortfolioLayout>
}